import api from "../../../config/api";
import { submitFormRequest, submitFormFailure, submitFormSuccess } from "../slice/flightDisruptionSlice";
import { put, takeLatest, call } from "redux-saga/effects";
import { config } from "../../../config/global";
const flightDisruptionCertData = async payload => {
  const POST_API_URL = config.FlIGHT_DISRUPTION_CERT;
  const headerData = {
    mode: "no-cors",
    headers: {
      Authorization: localStorage.getItem("accesstoken"),
      "Content-Type": "application/json"
    },
    loader: true,
    isGenericErr: true
  };
  let formSubmitPayload = {
    title: payload.title,
    firstName: payload.firstName,
    lastName: payload.lastName,
    frequentFlyerProgramme: payload.frequentFlyerProgramme,
    programmeNumber: payload.programmeNumber,
    email: payload.email,
    contactNumber: payload.contactNumber,
    country: payload.country,
    originalBookingReferenceNumber: payload.originalBookingReferenceNumber,
    originalAirline: payload.originalAirline,
    originalFlightDate: payload.originalFlightDate,
    originalFlightNumber: payload.originalFlightNumber,
    originalOrigin: payload.originalOrigin,
    originalDestination: payload.originalDestination,
    originalTicketNumber: payload.originalTicketNumber,
    actualAirline: payload.actualAirline,
    actualFlightDate: payload.actualFlightDate,
    actualFlightNumber: payload.actualFlightNumber,
    actualOrigin: payload.actualOrigin,
    actualDestination: payload.actualDestination,
    actualTicketNumber: payload.actualTicketNumber,
    actualBookingReferenceNumber: payload.actualBookingReferenceNumber,
    passportNumber: payload.passportNumber,
    certificateType: payload.certificateType,
    attachmentList: payload.attachmentList,
    subCategory: payload.subCategory
  };
  const response = await api.post(POST_API_URL, formSubmitPayload, headerData);
  return response;
};
function* postFormSubmitData(action) {
  const {
    payload
  } = action;
  try {
    const response = yield call(flightDisruptionCertData, payload);
    yield put(submitFormSuccess(response.data));
  } catch (error) {
    yield put(submitFormFailure(error));
  }
}
export function* watchFlightDisruptionCertData() {
  yield takeLatest(submitFormRequest.type, postFormSubmitData);
}