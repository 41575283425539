import { call, put, takeEvery } from "redux-saga/effects";
import { flightInfoFail, flightInfoRequest, flightInfoSuccess } from "../slice/flightInfoSlice";
import api from "../../../config/api";
import { config } from "../../../config/global";
let API_URL;
const fetchFlightInfo = async payload => {
  var _payload$info, _payload$info2, _payload$info3;
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  if (!(payload !== null && payload !== void 0 && payload.isMultiCity)) {
    API_URL = config === null || config === void 0 ? void 0 : config.FLIGHT_INFO_API;
  } else {
    API_URL = "".concat(config === null || config === void 0 ? void 0 : config.FLIGHT_INFO_API, "?multicity=true");
  }
  let searchPayload = {
    flightInfo: {
      departure: payload === null || payload === void 0 || (_payload$info = payload.info) === null || _payload$info === void 0 || (_payload$info = _payload$info.flightInfo) === null || _payload$info === void 0 ? void 0 : _payload$info.departure,
      arrival: payload === null || payload === void 0 || (_payload$info2 = payload.info) === null || _payload$info2 === void 0 || (_payload$info2 = _payload$info2.flightInfo) === null || _payload$info2 === void 0 ? void 0 : _payload$info2.arrival,
      departureDate: payload === null || payload === void 0 || (_payload$info3 = payload.info) === null || _payload$info3 === void 0 || (_payload$info3 = _payload$info3.flightInfo) === null || _payload$info3 === void 0 ? void 0 : _payload$info3.departureDate
    },
    tripType: payload === null || payload === void 0 ? void 0 : payload.tripType
  };
  const response = await api.post(API_URL, searchPayload, headerData);
  return response;
};
const starAirportSearchCallback = async payload => {
  var _payload$info4, _payload$info5, _payload$info6, _payload$info7;
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken"
    },
    loader: true,
    isGenericErr: true
  };
  let searchPayload = {
    flightInfo: {
      departure: payload === null || payload === void 0 || (_payload$info4 = payload.info) === null || _payload$info4 === void 0 || (_payload$info4 = _payload$info4.flightInfo) === null || _payload$info4 === void 0 ? void 0 : _payload$info4.departure,
      arrival: payload === null || payload === void 0 || (_payload$info5 = payload.info) === null || _payload$info5 === void 0 || (_payload$info5 = _payload$info5.flightInfo) === null || _payload$info5 === void 0 ? void 0 : _payload$info5.arrival,
      departureDate: payload === null || payload === void 0 || (_payload$info6 = payload.info) === null || _payload$info6 === void 0 || (_payload$info6 = _payload$info6.flightInfo) === null || _payload$info6 === void 0 ? void 0 : _payload$info6.departureDate
    },
    zoneDirection: payload === null || payload === void 0 || (_payload$info7 = payload.info) === null || _payload$info7 === void 0 ? void 0 : _payload$info7.zoneDirection,
    tripType: payload === null || payload === void 0 ? void 0 : payload.tripType
  };
  const response = await api.post(config.STAR_ALLIANCE_SEARCH, searchPayload, data);
  return response;
};
function* fetchFlightInfoSaga(action) {
  const {
    payload
  } = action;
  try {
    let response;
    if (!(payload !== null && payload !== void 0 && payload.isStarAlliance)) {
      response = yield call(fetchFlightInfo, payload);
    } else {
      response = yield call(starAirportSearchCallback, payload);
    }
    yield put(flightInfoSuccess({
      ...response,
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  } catch (error) {
    yield put(flightInfoFail({
      ...(error === null || error === void 0 ? void 0 : error.response),
      moreInfo: payload.moreInfo,
      id: payload.id
    }));
  }
}
export function* watchFlightInfo() {
  yield takeEvery(flightInfoRequest.type, fetchFlightInfoSaga);
}